import { Link, useNavigate } from "react-router-dom";
import "./styles.css";
import { useState } from "react";
import styled from "styled-components";
import Lottie from "react-lottie";
import downAnimation from "../../animations/down-animation.json";

const ListIcon = styled.img`
  width: 24px;
`;

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: downAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const Presentation = () => {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  function onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    fetch("https://formcarry.com/s/FNHyPUAmDP9", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, email }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.code === 200) {
          navigate("/video-clase");
        }
      })
      .catch((error) => {
        console.log("Form submission error", error);
      });
  }

  return (
    <div className="presentation-container">
      <span className="header-text">
        · VÍDEO <strong>GRATIS</strong> DE 14 MINUTOS ·
      </span>
      <span className="title">
        <span className="strong">Desarrolla</span> la habilidad{" "}
        <span className="strong">más demandada</span> por las empresas y{" "}
        <span className="strong">
          genera +3.000€ al mes trabajando desde cualquier parte del mundo
        </span>{" "}
        en 90 días
      </span>
      <span className="subtitle">... aunque no tengas experiencia y sin montar un negocio</span>
      <div>
        <ul className="list">
        <li className="list-element-container">
          <ListIcon src="/assets/checkbox-list.png" width={24} />
          <span className="list-element">
            <strong>Tu trabajo no te llena</strong>, y quieres un cambio profesional que te permita
            evolucionar aprendiendo una profesión de futuro.
          </span>
        </li>
        <li className="list-element-container">
          <ListIcon src="/assets/checkbox-list.png" width={24} />
          <span className="list-element">
            Los precios de la comida, luz, alquiler, combustible, etc.,
            <strong> no paran de subir</strong>, y necesitas una profesión con mejores salarios.
          </span>
        </li>
        <li className="list-element-container">
          <ListIcon src="/assets/checkbox-list.png" width={24} />
          <span className="list-element">
            Descubre la habilidad que te va permitir tener <strong> seguridad financiera </strong>y
            conseguir más tiempo para ti y tu familia gracias al trabajo remoto.
          </span>
        </li>
      </ul>
      <Lottie 
	    options={defaultOptions}
        height={60}
        width={60}
      />
      <form
        action="https://formspree.io/f/xpzgrlvp"
        method="POST"
        className="form-container"
        onSubmit={onSubmit}
      >
        <div className="form">
          <input
            type="text"
            placeholder="Nombre *"
            required
            className="input"
            name="name"
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="email"
            placeholder="Tu mejor email *"
            required
            className="input"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="checkbox-container">
          <input type="checkbox" className="checkbox" id="checkbox" required />
          <label htmlFor="checkbox" className="checkbox-text">
            He leído la <Link to="/legal">política de privacidad</Link> y acepto recibir contenidos
            formativos o comunicaciones comerciales
          </label>
        </div>
        <button type="submit" className="button">
          VER VÍDEO GRATIS
        </button>
      </form>
      </div>
      
      <div className="hero-container">
        <img className="hero-img" src="assets/alex.png" alt="alex" width={150} />
        <div className="hero-text-container">
          <h2 className="hero-title">Alex Alcántara</h2>
          <p className="hero-text">
            Alex es director comercial y CEO de Closer Profesional. Con el método que te presentará
            en el vídeo gratis, ha formado a
            <strong> centenares de personas a aprender la nueva profesión “Closer de Ventas”</strong>{" "}
            con la que cambió sus vidas por completo.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Presentation;
