import styled from "styled-components";
import VideoPlayer from "../components/VideoPlayer";
import BlackHeader from "../components/BlackHeader";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 75px);
  background-color: #191919;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content; center;
`;

const Wrapper = styled.div`
  max-width: 1000px;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  font-size: 18px;
  color: #1c48b0;
  font-weight: 800;
  margin: 0px;

  @media (min-width: 768px) {
    font-size: 20px;
  }
`;

const Description = styled.p`
  color: #fff;
  font-size: 20px;
  margin: 20px 0 40px 0;
  text-align: center;
  max-width: 800px;

  @media (min-width: 768px) {
    font-size: 28px;
  }
`;

const Button = styled.div`
  cursor: pointer;
  margin-top: 30px;
  max-width: 350px;
  background-image: linear-gradient(95deg, #1c48b0 36%, #5e94ff);
  padding: 10px;
  border-radius: 50px;
  border: 4px rgba(255, 255, 255, 0.42);
  outline: 4px solid rgba(118, 157, 255, 0.28);
  padding: 10px 40px;
  text-align: center;
`;

const ButtonText = styled.a`
  text-align: center;
  color: #fff;
  font-weight: 600;
  margin: 0;
  text-decoration: none;
`;

function VideoPage() {
  const lastTimeRecorded = parseFloat(localStorage.getItem("lastPlayedTime"));

  return (
    <>
      <BlackHeader />
      <Container>
        <Wrapper>
          <Title>· VÍDEO DE 14 MINUTOS ·</Title>
          <Description>
            Desarrolla la habilidad<strong> más demandada</strong> por las empresas y genera +3.000€
            al mes trabajando
            <strong> desde cualquier parte del mundo</strong> en 90 días
          </Description>
          <VideoPlayer />
          <div>
            {lastTimeRecorded && (
              <Button>
                <ButtonText
                  href="https://calendly.com/alexalcantara/sesion-de-claridad"
                  target="_blank"
                >
                  AGENDAR SESIÓN DE CONSULTORÍA GRATUITA
                </ButtonText>
              </Button>
            )}
          </div>
        </Wrapper>
      </Container>
    </>
  );
}

export default VideoPage;
