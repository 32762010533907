import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em 0;
  border-bottom: 1px solid #f5f6f7;
`;

const TitleWrapper = styled.div`
  position: relative;
`;

const Title = styled.h1`
font-weight: 400;
font-size: 16px;
letter-spacing: 5px;
word-spacing: 10px;
margin: 0;
`;

export default function Header() {
  return (
    <Container className="header-container">
      <TitleWrapper>
        <Title className="header-title">ALEX ALCÁNTARA</Title>
      </TitleWrapper>
    </Container>
  );
}
