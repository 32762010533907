import {
  faDownLeftAndUpRightToCenter,
  faPause,
  faPlay,
  faRotateLeft,
  faUpRightAndDownLeftFromCenter,
  faVolumeHigh,
  faVolumeXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";

const PlayerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: 0 2px 20px 6px rgba(127, 193, 255, 0.28);
`;

const ControlsWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  visibility: ${({ showControls }) => (showControls ? "visible" : "hidden")};

  @media (max-width: 768px) {
    bottom: 5px;
    left: 0px;
    right: 0px;
  }
`;

const Button = styled.button`
  background-color: transparent;
  border: none;
  color: white;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #00b2ff;
  }
`;

const LeftSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AgainWrapper = styled.div`
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AgainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;

  @media (min-width: 500px) {
    gap: 25px;
  }
`;

const AgainTitle = styled.h2`
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  margin: 0;

  @media (min-width: 500px) {
    font-size: 28px;
  }
`;

const AgainButton = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  width: 270px;
  cursor: pointer;

  justify-content: ${({ $action }) => ($action ? "center" : "flex-start")};

  @media (min-width: 500px) {
    width: 377px;
  }
`;

const AgainSubtitle = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: #fff;

  @media (min-width: 500px) {
    font-size: 24px;
  }
`;

const IconContainer = styled.div`
  padding-left: 2px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  border: 3px solid rgb(57, 116, 255);
`;

const ActionButton = styled.div`
  cursor: pointer;
  background-image: linear-gradient(95deg, #1c48b0 36%, #5e94ff);
  padding: 8px 15px;
  border-radius: 50px;
  text-align: center;

  @media (min-width: 500px) {
    padding: 15px 20px;
  }
`;

const ActionButtonText = styled.a`
  text-align: center;
  font-size: 13px;
  color: #fff;
  font-weight: 600;
  margin: 0;
  text-decoration: none;

  @media (min-width: 500px) {
    font-size: 18px;
  }
`;

const ACTION_SECOND = 740;

function VideoPlayer() {
  const isIPhone = /iPhone/i.test(navigator.userAgent);
  const lastTimeRecorded = parseFloat(localStorage.getItem("lastPlayedTime"));

  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [lastPlayedTime, setLastPlayedTime] = useState(null);
  const [showModal, setShowModal] = useState(lastTimeRecorded ? true : false);
  const [showActionModal, setShowActionModal] = useState(false);
  const [videoResumed, setVideoResumed] = useState(false);

  const playerRef = useRef(null);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const lastTime = parseFloat(localStorage.getItem("lastPlayedTime"));
    if (lastTime) {
      setLastPlayedTime(lastTime);
    }
  }, []);

  const triggerActionModal = () => {
    setShowActionModal(true);
    setPlaying(false);
  };

  const resumeVideo = () => {
    setShowActionModal(false);
    setShowModal(false);
    setPlaying(true);
    setVideoResumed(true);
  };

  const handleProgress = ({ playedSeconds }) => {
    if (playing) {
      localStorage.setItem("lastPlayedTime", playedSeconds.toString());

      if (playedSeconds >= ACTION_SECOND && playedSeconds <= ACTION_SECOND + 1.1 && !videoResumed)
        triggerActionModal();
    }
  };

  const togglePlay = () => {
    setPlaying(!playing);
  };

  const toggleVolume = () => {
    setMuted(!muted);
  };

  const handleStartOver = () => {
    setShowModal(false);
    setPlaying(true);
  };

  const handleResume = () => {
    if (playerRef.current && lastPlayedTime !== null) {
      playerRef.current.seekTo(lastPlayedTime, "seconds");
    }
    setShowModal(false);
    setPlaying(true);
  };

  const toggleFullScreen = () => {
    const player = wrapperRef.current;
    if (!isFullscreen) {
      if (player.requestFullscreen) {
        player.requestFullscreen();
      } else if (player.webkitRequestFullscreen) {
        player.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
    setIsFullscreen(!isFullscreen);
  };

  return (
    <PlayerWrapper ref={wrapperRef} onClick={togglePlay}>
      {showModal && (
        <AgainWrapper>
          <AgainContainer>
            <AgainTitle>Estás por aquí de nuevo?</AgainTitle>
            <AgainButton onClick={handleResume}>
              <IconContainer>
                <FontAwesomeIcon icon={faPlay} color="#fff" />
              </IconContainer>
              <AgainSubtitle>Continuar donde lo dejé</AgainSubtitle>
            </AgainButton>
            <AgainButton onClick={handleStartOver}>
              <IconContainer>
                <FontAwesomeIcon icon={faRotateLeft} color="#fff" />
              </IconContainer>
              <AgainSubtitle>Empezar desde el principio</AgainSubtitle>
            </AgainButton>
          </AgainContainer>
        </AgainWrapper>
      )}

      {showActionModal && (
        <AgainWrapper>
          <AgainContainer>
            <AgainTitle>Te lo explico 1 a 1</AgainTitle>
            <ActionButton
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <ActionButtonText
                href="https://calendly.com/alexalcantara/sesion-de-claridad"
                target="_blank"
              >
                Agendar sesión de consultoría gratuita
              </ActionButtonText>
            </ActionButton>
            <AgainButton onClick={resumeVideo} $action={true}>
              <IconContainer>
                <FontAwesomeIcon icon={faPlay} color="#fff" />
              </IconContainer>
              <AgainSubtitle>Continuar con el vídeo</AgainSubtitle>
            </AgainButton>
          </AgainContainer>
        </AgainWrapper>
      )}

      <ReactPlayer
        config={{ file: { attributes: { playsInline: true } } }}
        ref={playerRef}
        url="https://cloudflarestream.com/7f3396e7626fc221ef3cd5029b44a01e/manifest/video.m3u8"
        playing={playing}
        muted={muted}
        width="100%"
        height="100%"
        controls={false}
        onProgress={handleProgress}
        className="react-player"
      />
      <ControlsWrapper showControls={true}>
        <Button onClick={togglePlay}>
          {playing ? <FontAwesomeIcon icon={faPause} /> : <FontAwesomeIcon icon={faPlay} />}
        </Button>

        <LeftSide>
          <Button
            onClick={(e) => {
              toggleVolume();
              e.stopPropagation();
            }}
          >
            {muted ? (
              <FontAwesomeIcon icon={faVolumeXmark} />
            ) : (
              <FontAwesomeIcon icon={faVolumeHigh} />
            )}
          </Button>

          {!isIPhone && (
            <Button
              onClick={(e) => {
                toggleFullScreen();
                e.stopPropagation();
              }}
            >
              {isFullscreen ? (
                <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} />
              ) : (
                <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
              )}
            </Button>
          )}
        </LeftSide>
      </ControlsWrapper>
    </PlayerWrapper>
  );
}

export default VideoPlayer;
