import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  background-color: #191919;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em 0;
  border-bottom: 1px solid #6B6B6B;
`;

const Title = styled.h1`
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 5px;
  word-spacing: 10px;
  color: #fff;
`;

export default function BlackHeader() {
  return (
    <Container className="header-container">
      <Title className="header-title">ALEX ALCÁNTARA</Title>
    </Container>
  );
}
