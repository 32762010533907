import { Route, Routes } from "react-router-dom";
import "./App.css";
import Presentation from "./pages/Presentation/Presentation";
import Legal from "./pages/Legal";
import VideoPage from "./pages/VideoPage";
import Header from "./components/Header";

function App() {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <>
          <Header />
          <div className="app-container">
            <div className="content-container">
              <Presentation />
            </div>
          </div></>
        }
      />
      <Route exact path="/video-clase" element={<VideoPage />} />
      <Route exact path="/legal" element={<Legal />} />
    </Routes>
  );
}

export default App;
