const Legal = () => {
  return (
    <div>
      <h1>Política de Privacidad</h1>
      <p>Actualizado por última vez el 20 de Enerao de 2023.</p>
      <p>
        Información en cumplimiento de la normativa de protección de datos
        personales a nivel internacional.
      </p>
      <p>
        En prácticamente la totalidad de los Países existen normas de protección
        de datos pensadas para proteger su información personal.
      </p>
      <p>
        Por ello, es muy importante para nosotros que entienda perfectamente qué
        vamos a hacer con los datos personales que le pedimos. Así, seremos
        transparentes y le daremos el control de sus datos, con un lenguaje
        sencillo y opciones claras que le permitirán decidir qué haremos con su
        información personal.
      </p>
      <p>
        Por favor, si una vez leída la presente información le queda alguna
        duda, no dude en preguntarnos. Muchas gracias por su colaboración.
      </p>
      <h2>¿Quiénes somos?</h2>
      <p>
        Mentoria Ventas Alex Alcantara, es una academia online para varios
        países, y en este caso, para todos los países de habla hispana.
      </p>
      <p>Autonomo, con NIF 48181899G en España.</p>
      <p>Dirección de correo electrónico de contacto: aalcantaraff@gmail.com</p>
      <h2>¿Para qué vamos a usar sus datos?</h2>
      <p>
        Con carácter general, sus datos personales serán usados para poder
        relacionarnos con usted y poder prestarle nuestros servicios, incluyendo
        también el comercio electrónico, también conocido como e-commerce,
        consistente en la compra y venta de productos o de servicios a través de
        medios electrónicos. Asimismo, también pueden ser usados para otras
        actividades, como enviarle publicidad o promocionar nuestras
        actividades, así como la emisión de nuestras facturas a su nombre.
      </p>
      <h2>¿Por qué necesitamos usar sus datos?</h2>
      <p>
        Sus datos personales son necesarios para poder relacionarnos con usted y
        poder prestarle nuestros servicios. En este sentido, pondremos a su
        disposición una serie de casillas que le permitirán decidir de manera
        clara y sencilla sobre el uso de su información personal. Toda la
        información que le requerimos será utilizada para contribuir al correcto
        desenvolvimiento de la relación de compraventa de productos que nos une
        con usted. Datos tales como direcciones o números de teléfono, que en
        principio parecen no estar relacionados con la actividad de comercio
        electrónico, serán utilizados únicamente para asegurarnos de que pueda
        disfrutar de los productos adquiridos a través de este sitio web. La
        información que nos proporcione deberá ser veraz para que los servicios
        de comercio electrónico puedan prestarse de manera coherente y efectiva.
        Sería interesante, además, que actualice en todo momento la información
        que nos proporcione para evitar errores o confusiones futuras que puedan
        quebrar la correcta prestación de servicios.
      </p>
      <h2>¿Quién va a conocer la información que le pedimos?</h2>
      <p>
        Con carácter general, sólo el personal de nuestra entidad que esté
        debidamente autorizado podrá tener conocimiento de la información que le
        pedimos. De igual modo, podrán tener conocimiento de su información
        personal aquellas entidades que necesiten tener acceso a la misma para
        que podamos prestarle nuestros servicios. Así por ejemplo, nuestro banco
        conocerá sus datos si el pago de nuestros servicios se realiza mediante
        tarjeta o transferencia bancaria.
      </p>
      <p>
        Asimismo, tendrán conocimiento de su información aquellas entidades
        públicas o privadas a las cuales estemos obligados a facilitar sus datos
        personales con motivo del cumplimiento de alguna ley. Poniéndole un
        ejemplo, la Ley Tributaria obliga a facilitar a la Agencia Tributaria
        determinada información sobre operaciones económicas que superen una
        determinada cantidad.
      </p>
      <p>
        En el caso de que, al margen de los supuestos comentados, necesitemos
        dar a conocer su información personal a otras entidades, le
        solicitaremos previamente su permiso a través de opciones claras que le
        permitirán decidir a este respecto.
      </p>
      <h2>¿Cómo vamos a proteger sus datos?</h2>
      <p>
        Protegeremos sus datos con medidas de seguridad eficaces en función de
        los riesgos que conlleve el uso de su información. Para ello, nuestra
        entidad ha aprobado una Política de Protección de Datos y se realizan
        controles y auditorías anuales para verificar que sus datos personales
        están seguros en todo momento.
      </p>
      <h2>¿Enviaremos sus datos a otros países?</h2>
      <p>
        En el mundo hay países que son seguros para sus datos y otros que no lo
        son tanto. Así por ejemplo, la Unión Europea es un entorno seguro para
        sus datos. Nuestra política es no enviar su información personal a
        ningún país que no sea seguro desde el punto de vista de la protección
        de sus datos. En el caso de que, con motivo de prestarle el servicio,
        sea imprescindible enviar sus datos a cualquier país siempre le
        solicitaremos previamente su permiso o le comunicaremos que lo hemos
        hecho si nos vemos obligados a hacerlo por mandato legal y aplicaremos
        medidas de seguridad eficaces que reduzcan los riesgos del envío de su
        información personal a otro país.
      </p>
      <h2>¿Durante cuánto tiempo vamos a conservar sus datos?</h2>
      <p>
        Conservaremos sus datos durante nuestra relación y mientras nos obliguen
        las leyes. Una vez finalizados los plazos legales aplicables,
        procederemos a eliminarlos de forma segura y respetuosa con el medio
        ambiente.
      </p>
      <h2>¿Cuáles son sus derechos de protección de datos?</h2>
      <p>
        En cualquier momento puede dirigirse a nosotros para saber qué
        información tenemos sobre usted, rectificarla si fuese incorrecta y
        eliminarla una vez finalizada nuestra relación, en el caso de que ello
        sea legalmente posible.
      </p>
      <p>
        También tiene derecho a solicitar el traspaso de su información a otra
        entidad. Este derecho se llama “portabilidad” y puede ser útil en
        determinadas situaciones.
      </p>
      <p>
        Para solicitar alguno de estos derechos, deberá realizar una solicitud
        escrita a nuestra dirección, junto con una fotocopia de su documento de
        identidad, para poder identificarle.
      </p>
      <p>
        En las oficinas de nuestra entidad disponemos de formularios específicos
        para solicitar dichos derechos y le ofrecemos nuestra ayuda para su
        cumplimentación.
      </p>
      <h2>
        ¿Puede retirar su consentimiento si cambia de opinión en un momento
        posterior?
      </h2>
      <p>
        Usted puede retirar su consentimiento si cambia de opinión sobre el uso
        de sus datos en cualquier momento.
      </p>
      <p>
        Así por ejemplo, si usted en su día estuvo interesado/a en recibir
        publicidad de nuestros productos o servicios, pero ya no desea recibir
        más publicidad, puede hacérnoslo constar a través del formulario de
        oposición al tratamiento disponible en las oficinas de nuestra entidad.
      </p>
      <p>
        En caso de que entienda que sus derechos han sido desatendidos, ¿dónde
        puede formular una reclamación? En caso de que entienda que sus derechos
        han sido desatendidos por nuestra entidad, puede formular una
        reclamación en la Agencia Protección de Datos de su país, a través de
        alguno de los medios siguientes: Formular una reclamación en la Agencia
        de Protección de Datos no conlleva ningún coste y no es necesaria la
        asistencia de abogado ni procurador.
      </p>
      <h2>¿Elaboraremos perfiles sobre usted?</h2>
      <p>
        Nuestra política es no elaborar perfiles sobre los usuarios de nuestros
        servicios. No obstante, pueden existir situaciones en las que, con fines
        de prestación del servicio, comerciales o de otro tipo, necesitemos
        elaborar perfiles de información sobre usted. Un ejemplo pudiera ser la
        utilización de su historial de compras o servicios para poder ofrecerle
        productos o servicios adaptados a sus gustos o necesidades.
      </p>
      <p>
        En tal caso, aplicaremos medidas de seguridad eficaces que protejan su
        información en todo momento de personas no autorizadas que pretendan
        utilizarla en su propio beneficio.
      </p>
      <h2>¿Usaremos sus datos para otros fines?</h2>
      <p>
        Nuestra política es no usar sus datos para otras finalidades distintas a
        las que le hemos explicado. Si, no obstante, necesitásemos usar sus
        datos para actividades distintas, siempre le solicitaremos previamente
        su permiso a través de opciones claras que le permitirán decidir al
        respecto.
      </p>
    </div>
  );
};

export default Legal;
